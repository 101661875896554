import { InMemoryCache } from '@apollo/client'

const makePaginationMergeFn =
  ({ queryField, getPageRequest }) =>
  (existing, incoming, { args }) => {
    const mergedData = existing?.[queryField]
      ? existing[queryField].slice(0)
      : []
    const incomingData = incoming[queryField] ?? []

    const page = getPageRequest(args).page
    const size = getPageRequest(args).size

    const startIndex = page * size

    for (let i = 0; i < incomingData.length; ++i) {
      mergedData[startIndex + i] = incomingData[i]
    }

    return {
      ...incoming,
      [queryField]: mergedData,
    }
  }

export const cache: InMemoryCache = new InMemoryCache({
  possibleTypes: {
    NotificationValue: [
      'NotificationValueString',
      'NotificationValueAccounts',
      'NotificationValueTaskDeleted',
      'Timestamp',
    ],
  },
  typePolicies: {
    Query: {
      fields: {
        getCurrentUserAccount: {
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming)
          },
        },
        dynamicPlannings: {
          merge(existing, incoming) {
            return incoming
          },
        },
        searchContacts: {
          merge(existing, incoming) {
            return incoming
          },
        },
        getLoggedTimes: {
          merge(existing, incoming) {
            return incoming
          },
        },
        getGoal: {
          keyArgs: ['input', 'includeResourcesForGoalTree'],
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming)
          },
        },
        getTask: {
          keyArgs: ['input'],
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming)
          },
        },
        searchThreads: {
          keyArgs: ['input', ['sorters', 'filters']],
          merge: makePaginationMergeFn({
            queryField: 'threads',
            getPageRequest: (args) => args?.input?.pageRequest,
          }),
        },
        recentThreads: {
          keyArgs: ['unreadOnly'],
          merge: makePaginationMergeFn({
            queryField: 'activities',
            getPageRequest: (args) => args?.pageRequest,
          }),
        },
        searchBoards: {
          keyArgs: ['input', ['sorters', 'filters']],
          merge: makePaginationMergeFn({
            queryField: 'boards',
            getPageRequest: (args) => args?.input?.pageRequest,
          }),
        },
        searchFashionCollections: {
          keyArgs: ['input', ['sorters', 'filters']],
          merge: makePaginationMergeFn({
            queryField: 'fashionCollections',
            getPageRequest: (args) => args?.input?.pageRequest,
          }),
        },
      },
    },
    Account: {
      merge: true,
      fields: {
        calendars: {
          merge(existing, incoming) {
            return incoming
          },
        },
      },
    },
    Project: {
      fields: {
        assignedProducers: {
          merge(existing, incoming) {
            return incoming
          },
        },
        materials: {
          merge(existing, incoming) {
            return incoming
          },
        },
      },
    },
    Section: {
      fields: {
        goals: {
          merge(existing, incoming) {
            return incoming
          },
        },
      },
    },
    Task: {
      fields: {
        assignees: {
          merge(existing, incoming) {
            return incoming
          },
        },
        followers: {
          merge(existing, incoming) {
            return incoming
          },
        },
        taskTree: {
          merge(existing, incoming) {
            if (existing && !incoming) {
              return existing
            }

            return incoming
          },
        },
      },
    },
    RecurringTaskDefinition: {
      fields: {
        followers: {
          merge(existing, incoming) {
            return incoming
          },
        },
      },
    },
    Board: {
      fields: {
        sections: {
          merge(existing, incoming) {
            return incoming
          },
        },
      },
    },
    Goal: {
      fields: {
        resources: {
          merge(existing, incoming) {
            return incoming
          },
        },
        labels: {
          merge(existing, incoming) {
            return incoming
          },
        },
        comments: {
          merge(existing, incoming) {
            return incoming
          },
        },
        followers: {
          merge(existing, incoming) {
            return incoming
          },
        },
        commentsState: {
          merge: true,
        },
      },
    },
    Thread: {
      fields: {
        comments: {
          merge(existing, incoming) {
            return incoming
          },
        },
      },
    },
    Comment: {
      fields: {
        recipients: {
          merge(existing, incoming) {
            return incoming
          },
        },
        likeDetails: {
          merge(existing, incoming) {
            return incoming
          },
        },
      },
    },
    ProcedureMilestone: {
      fields: {
        milestones: {
          merge(existing, incoming) {
            return incoming
          },
        },
      },
    },
    Team: {
      fields: {
        members: {
          merge(existing, incoming) {
            return incoming
          },
        },
      },
    },
    Counters: {
      keyFields: ['accountId'],
      fields: {
        tasks: {
          merge(existing, incoming) {
            return {
              ...existing,
              ...incoming,
            }
          },
        },
        todos: {
          merge(existing, incoming) {
            return {
              ...existing,
              ...incoming,
            }
          },
        },
      },
    },
    Resource: {
      fields: {
        presignedUrl: {
          merge(existing, incoming) {
            // use the existing cached image
            // this way we skip downloading of images with new signed urls every time
            if (existing && incoming) {
              return existing
            }

            // update the cache when there is no cached image
            return incoming
          },
        },
      },
    },
    DynamicPlanning: {
      keyFields: ({
        goal,
        milestone,
        task,
        project,
        order,
        thread,
        projectMaterial,
        materialsProductionRequest,
      }) =>
        (goal as any)?.__typename ||
        (milestone as any)?.__typename ||
        (task as any)?.__typename ||
        (project as any)?.__typename ||
        (order as any)?.__typename ||
        (thread as any)?.__typename ||
        (projectMaterial as any)?.__typename ||
        (materialsProductionRequest as any)?.__typename,
    },
  },
})
